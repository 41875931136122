import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { $ } from "../utils/query-selector.js";

const MENU_BREAKPOINT = "(min-width: 64em)"; // 1024px

class Menu {
  constructor(el) {
    this.el = el;

    this.toggleButton = $(".js-menu__toggle", this.el);
    this.items = $(".js-menu__items", this.el);
    this.list = $(".js-menu__list", this.el);

    this.toggleButton.addEventListener("click", this.toggle.bind(this));

    this.mediaQuery = matchMedia(MENU_BREAKPOINT);
    // Use deprecated addListener method for backwards compatibility
    this.mediaQuery.addListener(this.setDesktopOrMobileMenu.bind(this));
    this.setDesktopOrMobileMenu();
  }

  isDesktop() {
    return this.mediaQuery.matches;
  }

  toggle(force) {
    const oldState = this.toggleButton.getAttribute("aria-expanded") === "true";
    const newState = typeof force === "boolean" ? force : !oldState;

    if (oldState === newState) {
      return;
    }

    if (newState && !this.isDesktop()) {
      scrollTo(0, 0);
    }

    this.toggleButton.setAttribute("aria-expanded", newState.toString());

    this.el.classList.toggle("is-open", newState);

    if (newState) {
      disableBodyScroll(this.list, {
        reserveScrollBarGap: true,
      });
    } else {
      enableBodyScroll(this.list);
    }
  }

  setDesktopOrMobileMenu() {
    if (this.isDesktop()) {
      this.toggle(false);
    }
  }
}

export default function initMenu() {
  const el = $(".js-menu");
  if (!el) {
    return;
  }

  // eslint-disable-next-line no-new
  new Menu(el);
}
