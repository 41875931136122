import { $ } from "../utils/query-selector.js";
import Flickity from "flickity";
import "flickity-fade";

export default function initStage() {
  const el = $(".js-stage");
  const carouselEl = $(".js-stage__carousel");

  if (!el || !carouselEl) {
    return;
  }

  // Shuffle photos
  for (let i = carouselEl.children.length; i >= 0; i--) {
    carouselEl.appendChild(carouselEl.children[Math.floor(Math.random() * i)]);
  }

  // eslint-disable-next-line no-new
  new Flickity(carouselEl, {
    accessibility: false, // disable keyboard nav
    draggable: false,
    autoPlay: 3000,
    fade: true,
    // lazyLoad: true, => Cannot use lazyload here, because it breaks the fade transition: https://github.com/metafizzy/flickity-fade/issues/9
    prevNextButtons: false,
    pageDots: false,
    setGallerySize: false,
    pauseAutoPlayOnHover: false,
    selectedAttraction: 0.01,
    friction: 0.15,
  });

  // Prevent Flickity from receiving any pointer events.

  const noop = (e) => {
    e.stopImmediatePropagation();
    e.stopPropagation();
  };

  carouselEl.addEventListener("mouseenter", noop, true);
  const viewport = $(".flickity-viewport", carouselEl);

  if (viewport) {
    viewport.addEventListener("click", noop, true);
    viewport.addEventListener("pointerdown", noop, true);
  }
}
