import { $, $$ } from "../utils/query-selector.js";
import Flickity from "flickity";

const LOGOS_BREAKPOINT = "(min-width: 44em)"; // >= 704px
const TICKER_SPEED = 0.75;

class SectionLogos {
  constructor(el) {
    this.el = el;
    this.flickity = null;
    this.flickityAnimationFrame = null;
    this.items = $(".js-section-logos__items", el);
    this.mediaQuery = matchMedia(LOGOS_BREAKPOINT);

    this.mediaQuery.addListener(this.setGridOrCarouselLayout.bind(this));
    this.setGridOrCarouselLayout();
  }

  isGrid() {
    return this.mediaQuery.matches;
  }

  setGridOrCarouselLayout() {
    if (this.isGrid()) {
      if (this.flickity) {
        cancelAnimationFrame(this.flickityAnimationFrame);
        requestAnimationFrame(() => {
          this.flickity.destroy();
          this.flickity = null;
        });
      }
    } else if (!this.flickity) {
      // eslint-disable-next-line no-new
      this.flickity = new Flickity(this.items, {
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false,
        // setGallerySize: false,
        wrapAround: true,
      });

      let isPaused = false;

      const updateTicker = () => {
        if (isPaused) {
          return;
        }

        if (!this.flickity.slides) {
          return;
        }

        this.flickity.x =
          (this.flickity.x - TICKER_SPEED) % this.flickity.slideableWidth;
        this.flickity.selectedIndex = this.flickity.dragEndRestingSelect();
        this.flickity.updateSelectedSlide();
        this.flickity.settle(this.flickity.x);
        this.flickityAnimationFrame = requestAnimationFrame(updateTicker);
      };

      this.flickity.on("dragStart", () => {
        isPaused = true;
      });

      this.flickity.on("staticClick", () => {
        isPaused = true;
      });

      updateTicker();
    }
  }
}

export default function initSectionLogos() {
  for (const el of $$(".js-section-logos")) {
    // eslint-disable-next-line no-new
    new SectionLogos(el);
  }
}
